import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";
import { useCallback } from "react";

export const useFindEmployees = () => {
  const { data, isLoading, mutate } = trpc.findEmployees.useMutation();

  const fetch = useCallback(
    (input: {
      companyId?: string;
      ids?: string[];
      limit: number;
      page: number;
      search?: string;
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os colaboradores",
            type: "error",
          });
        },
      });
    },
    [mutate],
  );
  return { data, isLoading, fetch };
};
