import { useState } from "react";
import { PageContainer, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  Body,
  Container,
  ContentWrapper,
  FieldDescriptionInput,
  FieldTitleInput,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledSubtitleContainer,
  StyledTitle,
  TextWrapper,
} from "./styled";

const MAX_DESCRIPTION_LENGTH = 600;

export const PageCreateGroup = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Criar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Informações básicas
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Adicione o nome e descrição do grupo.
              </StyledDescription>
            </TextWrapper>
            <FormWrapper>
              <StyledFormTitle variant="headline8">
                Nome e descrição
              </StyledFormTitle>
              <StyledFormDescription variant="body4">
                Dê um nome ao grupo, este será o principal texto exibido nas
                listas e nos destaques. Na descrição, você pode descrever um
                resumo e qual o objetivo do grupo.
              </StyledFormDescription>
              <FieldTitleInput
                label="Nome do grupo"
                onChange={(e) => setName(e.target.value)}
              />
              <FieldDescriptionInput
                label="Descrição do grupo"
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                multiline
                rows={4}
                inputMode="text"
              />
              <StyledSubtitleContainer>
                <Typography variant="caption">
                  {`Máximo ${MAX_DESCRIPTION_LENGTH} caracteres`}
                </Typography>
                <Typography variant="caption">
                  {`${description.length}/${MAX_DESCRIPTION_LENGTH}`}
                </Typography>
              </StyledSubtitleContainer>
            </FormWrapper>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={!!!name.length}
        name={name}
        description={description}
      />
    </Body>
  );
};
