import { useNavigate } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";
import { useCompany } from "@/lib";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";
import { useCreateGroup } from "@/hooks";

export const Footer = ({
  disableForward,
  name,
  description,
}: {
  disableForward: boolean;
  name: string;
  description?: string;
}) => {
  const navigate = useNavigate();
  const { companyId } = useCompany();
  const { createGroup, isLoading } = useCreateGroup();

  return (
    <Container>
      <CancelButton
        variant="secondary"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => navigate(routes.pageGroups())}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={isLoading}
          onClick={async () => {
            createGroup({
              companyId,
              name,
              ...(description && { description }),
            });
          }}
        >
          Continuar <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
