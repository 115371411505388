import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContainer, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  Body,
  Container,
  ContentWrapper,
  FieldTitleInput,
  FieldDescriptionInput,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledSubtitleContainer,
  StyledTitle,
  TextWrapper,
} from "./styled";
import { useFindGroupById } from "@/hooks";

const MAX_DESCRIPTION_LENGTH = 600;

export const PageEditGroup = () => {
  const { groupId } = useParams();
  const { data: group } = useFindGroupById({ groupId: groupId! });

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(group?.name as any);
    setDescription(group?.description as any);
  }, [group]);

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Editar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Informações básicas
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Edite o nome do grupo <strong>{name}</strong>
              </StyledDescription>
            </TextWrapper>
            <FormWrapper>
              <StyledFormTitle variant="headline8">Nome</StyledFormTitle>
              <StyledFormDescription variant="body4">
                Edite o nome do grupo. Este é o principal texto exibido nas
                listas e nos destaques.
              </StyledFormDescription>
              <FieldTitleInput
                label="Nome do grupo"
                onChange={(e) => setName(e.target.value)}
                placeholder={name as any}
                value={name}
              />
              <FieldDescriptionInput
                label="Descrição do grupo"
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                multiline
                rows={4}
                inputMode="text"
                value={description}
              />
              <StyledSubtitleContainer>
                <Typography variant="caption">
                  {`Máximo ${MAX_DESCRIPTION_LENGTH} caracteres`}
                </Typography>
                <Typography variant="caption">
                  {`${description?.length || 0}/${MAX_DESCRIPTION_LENGTH}`}
                </Typography>
              </StyledSubtitleContainer>
            </FormWrapper>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={!!!name?.length && !!!description}
        name={name === group?.name ? undefined : name}
        description={
          description === group?.description ? undefined : description
        }
      />
    </Body>
  );
};
