import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";
import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";
import { useUpdateGroup } from "@/hooks";

type FooterProps = {
  disableForward: boolean;
  name?: string;
  description?: string;
};

export const Footer = ({
  disableForward,
  name,
  description,
}: FooterProps) => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const { updateGroup, isLoading } = useUpdateGroup();
  const navigate = useNavigate();

  return (
    <Container>
      <CancelButton
        variant="secondary"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => navigate(routes.pageGroups(groupId))}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={isLoading}
          onClick={async () => {
            updateGroup({
              groupId: groupId!,
              ...(name && { name }),
              ...(description && { description }),
            });

            navigate({
              pathname: routes.pageManageGroupMembers(groupId as any),
              search: searchParams.toString(),
            });
          }}
        >
          Continuar <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
