import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useQuery } from "@apollo/client";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { filter } from "lodash-es";
import {
  GetCompanySectionsFields,
  GetCompanySectionsFields_getCompany_sectionsFields,
} from "src/api/queries/__generated__/GetCompanySectionsFields";
import { GET_COMPANY_SECTIONS_FIELDS } from "src/api/queries/getCompanySectionsFields";
import { routes } from "src/routes";

import { Header } from "./Header";
import { StyledBreadcrumbs } from "./styled";
import { Tabs } from "./Tabs";

export const PageSectionsFields = () => {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();

  const [selectedCustomSection, setSelectedCustomSection] =
    useState<GetCompanySectionsFields_getCompany_sectionsFields>();

  const [selectedDefaultSection, setSelectedDefaultSection] =
    useState<GetCompanySectionsFields_getCompany_sectionsFields>();

  const { loading, data } = useQuery<GetCompanySectionsFields>(
    GET_COMPANY_SECTIONS_FIELDS,
    {
      variables: { companyId: selectedCompany.id },
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        const sectionFields = response?.getCompany?.sectionsFields || [];

        const customFields = sectionFields?.filter((e) => e?.type === "custom");
        const customSelected = customFields?.find(
          (e) => e?._id === selectedCustomSection?._id,
        );

        if (customSelected) setSelectedCustomSection(customSelected);
        else setSelectedCustomSection(customSections?.[0] || ([] as any));

        setSelectedDefaultSection(defaultSections?.[0] || ([] as any));
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao buscar as seções e campos. Por favor, tente novamente mais tarde.",
        });
      },
    },
  );

  const defaultSections = filter(
    data?.getCompany?.sectionsFields,
    (item: GetCompanySectionsFields_getCompany_sectionsFields) =>
      item.type === "default",
  );

  const customSections = filter(
    data?.getCompany?.sectionsFields,
    (item: GetCompanySectionsFields_getCompany_sectionsFields) =>
      item.type === "custom",
  );

  return (
    <PageContainer>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="secondary"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="secondary">Seções e campos</LinkButton>,
        ]}
      />
      <Header />
      <Tabs
        loading={loading}
        defaultSections={defaultSections as any}
        customSections={customSections as any}
        selectedCustomSection={selectedCustomSection as any}
        setSelectedCustomSection={setSelectedCustomSection as any}
        selectedDefaultSection={selectedDefaultSection as any}
        setSelectedDefaultSection={setSelectedDefaultSection as any}
      />
    </PageContainer>
  );
};
