import { useFindEmployeesByGroupId } from "@/hooks";
import { CardOptions } from "../CardOptions";
import { Card, TagStyled, Title, Wrapper } from "./styled";

export type BaseTabCardType = { key: string; title: string };

export interface TabCardInterface extends BaseTabCardType {
  id: string;
  isSelected: boolean;
  setSelectedTab: (key: string) => void;
}

export const TabCard = ({
  id,
  isSelected,
  title,
  setSelectedTab,
}: TabCardInterface) => {
  const { data: employees } = useFindEmployeesByGroupId({
    groupId: id,
    limit: 25,
    page: 1,
  });
  return (
    <Card key={id} selected={isSelected} onClick={() => setSelectedTab(id)}>
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
      <Wrapper>
        {employees?.metadata && (
          <TagStyled disabled={true} variant={isSelected ? "gray" : "primary"}>
            {`${employees?.metadata.totalCount}`}
          </TagStyled>
        )}
        <CardOptions
          groupId={id}
          active={false}
          groupName={title}
        />
      </Wrapper>
    </Card>
  );
};
