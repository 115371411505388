import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalConfirmSkip } from "@Components/ModalConfirmSkip";
import {
  DEPARTMENT_CREATE_BACK,
  DEPARTMENT_CREATE_CANCEL,
  DEPARTMENT_CREATE_CONTINUE,
  DEPARTMENT_CREATE_SKIP,
  DEPARTMENT_CREATE_SKIP_MODAL_CONTINUE,
} from "@departments/events";
import { DepartmentFormStep } from "@departments/types";
import { setEventTracking } from "@Utils/eventTracking";
import { routes } from "src/routes/index";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  CreateDepartmentButton,
  FirstWrapper,
  SecondWrapper,
} from "./styled";

interface FooterProps {
  loading: boolean;
  disableForwardFirstStep: boolean;
  disableForwardSecondStep: boolean;
  activeStep: DepartmentFormStep;
  setActiveStep: Dispatch<SetStateAction<DepartmentFormStep>>;
  handleCreateGroup: () => void;
  handleInsertEmployeeInDepartment: () => void;
}

export const Footer = ({
  loading,
  disableForwardFirstStep,
  disableForwardSecondStep,
  activeStep,
  setActiveStep,
  handleCreateGroup,
  handleInsertEmployeeInDepartment,
}: FooterProps) => {
  const navigate = useNavigate();
  const [skipModalIsOpen, setSkipModalIsOpen] = useState(false);

  const navigateToDepartments = () => {
    navigate(routes.departments);
  };

  return (
    <Container>
      <FirstWrapper>
        <CancelButton
          variant="secondary"
          onClick={() => {
            navigateToDepartments();
            setEventTracking(DEPARTMENT_CREATE_CANCEL, {
              step: activeStep.toString(),
            });
          }}
        >
          Cancelar
        </CancelButton>
        {activeStep === DepartmentFormStep.SECOND && (
          <CancelButton
            variant="secondary"
            onClick={() => {
              setSkipModalIsOpen(true);
              setEventTracking(DEPARTMENT_CREATE_SKIP, {
                step: activeStep.toString(),
              });
            }}
          >
            Pular e finalizar
          </CancelButton>
        )}
      </FirstWrapper>
      <SecondWrapper>
        {activeStep === DepartmentFormStep.FIRST && (
          <>
            <BackButton
              size="large"
              variant="secondary"
              onClick={() => {
                navigateToDepartments();
                setEventTracking(DEPARTMENT_CREATE_BACK, {
                  step: activeStep.toString(),
                });
              }}
            >
              <Icons name="IconArrowLeft" />
              Voltar
            </BackButton>
            <ContinueButton
              size="large"
              variant="primary"
              disabled={disableForwardFirstStep}
              onClick={() => {
                handleCreateGroup();
                setActiveStep(DepartmentFormStep.SECOND);
                setEventTracking(DEPARTMENT_CREATE_CONTINUE, {
                  step: activeStep.toString(),
                  button_text: "Continuar",
                });
              }}
            >
              Continuar <Icons name="IconArrowRight" />
            </ContinueButton>
          </>
        )}
        {activeStep === DepartmentFormStep.SECOND && (
          <>
            <BackButton
              size="large"
              variant="secondary"
              onClick={() => {
                setActiveStep(DepartmentFormStep.FIRST);
                setEventTracking(DEPARTMENT_CREATE_BACK, {
                  step: activeStep.toString(),
                });
              }}
            >
              <Icons name="IconArrowLeft" />
              Voltar
            </BackButton>
            <CreateDepartmentButton
              size="large"
              variant="primary"
              loading={loading}
              disabled={false}
              onClick={() => {
                if (!disableForwardSecondStep) {
                  handleInsertEmployeeInDepartment();
                } else {
                  setSkipModalIsOpen(true);
                  setEventTracking(DEPARTMENT_CREATE_CONTINUE, {
                    step: activeStep.toString(),
                    button_text: "Criar departamento",
                  });
                }
              }}
            >
              <>
                Criar departamento
                <Icons name="IconCheck" fill="transparent" />
              </>
            </CreateDepartmentButton>
          </>
        )}
      </SecondWrapper>
      <ModalConfirmSkip
        isOpen={skipModalIsOpen}
        loading={false}
        title="Você não adicionou ninguém ao departamento. Deseja sair mesmo assim?"
        description="Você poderá adicionar pessoas a este departamento em outro momento."
        submitText={
          <>
            <span>Criar departamento</span>
            <Icons name="IconCheck" size={30} fill="transparent" />
          </>
        }
        onClose={() => {
          setSkipModalIsOpen(false);
        }}
        onSubmit={async () => {
          setSkipModalIsOpen(false);
          setEventTracking(DEPARTMENT_CREATE_SKIP_MODAL_CONTINUE);
          navigate(routes.departments);
        }}
      />
    </Container>
  );
};
