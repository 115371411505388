import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useUpdateGroup = () => {
  const utils = trpc.useContext();
  const { mutate, isLoading } = trpc.updateGroup.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Grupo atualizado com sucesso!",
      });
      utils.findGroups.invalidate();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Erro ao atualizar grupo!",
      });
    },
  });

  return { updateGroup: mutate, isLoading };
};
