import { useMemo, useState } from "react";

import { Container } from "./styled";
import { TableFilters } from "./TableFilters";
import { TableGrid } from "./TableGrid";
import { TableHeader } from "./TableHeader";
import { TableSearch } from "./TableSearch";
import { Employee } from "bff/src/services/company-management.service";
import { useFindEmployeesByGroupId } from "@/hooks";

export const Table = ({
  loading,
  groupId,
  groupName,
}: {
  loading: boolean;
  groupId: string;
  groupName: string;
}) => {
  const [search, setSearch] = useState<string>("");
  const {data: employees, isLoading} = useFindEmployeesByGroupId({ groupId, limit: 25, page: 1})

  const filteredEmployees = useMemo(() => {
    return employees?.records.filter((e) =>
      e.name!.toLowerCase().includes(search.toLowerCase()),
    );
  }, [employees, search]);

  return (
    <Container>
      <TableHeader totalCount={employees?.records.length || 0} loading={loading} />
      <TableSearch
        onChange={(e) => setSearch(e)}
        disabled={!!!employees?.records.length}
      />
      <TableFilters
        groupId={groupId}
        groupName={groupName}
        disabled={!groupId}
      />
      <TableGrid
        data={filteredEmployees as any}
        pageSize={10}
        groupId={groupId}
        groupName={groupName}
      />
    </Container>
  );
};
