import { trpc } from "@/api/client";
import { routes } from "@/routes";
import { dispatchToast } from "@/utils";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useCreateGroup = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate, isLoading } = trpc.createGroup.useMutation();
  const createGroup = useCallback(
    (input: { companyId: string; name: string; description?: string }) => {
      mutate(input, {
        onSuccess: (res) => {
          dispatchToast({
            type: "success",
            content: "Grupo criado com sucesso!",
          });
          searchParams.set("groupName", res.name);
          navigate({
            pathname: routes.pageManageGroupMembers(res.id),
            search: searchParams.toString(),
          });
        },
      });
    },
    [mutate],
  );

  return { createGroup, isLoading };
};
